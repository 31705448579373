
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card text-left">
      <div class="card-body">
        <b-tabs
          active-nav-item-class="nav nav-tabs"
          content-class="mt-3"
        >
          <b-tab title="Quotations" active>

            <div class="row">

              <div class="col-md-3">
                <label><strong>Filter by date:</strong></label> <br/>
                <date-picker style="width: 44%;"   :lang="lang" v-model="startDate" valueType="format" :first-day-of-week="1"></date-picker> to
                <date-picker style="width: 44%;"   :lang="lang" v-model="endDate" valueType="format"  @input="getQuotationsData" :first-day-of-week="1"></date-picker>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label><strong>Filter by status:</strong></label> <br/>
                  <select class="form-control" v-model="quotationStatus" @change="getQuotationsData" >
                    <option value="all">  All </option>
                    <option value="CONFIRMED" > Confirmed</option>
                    <option value="PENNDING" > Pending</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">

              </div>

              <div class="col-md-3">
                <div class="input-group mb-3">

                  <input type="text" v-model="keyword" class="form-control" placeholder="Search Customer" aria-label="" aria-describedby="basic-addon1">

                  <div class="input-group-append">
                    <button  class="btn btn-primary" style="color:#fff !important;" @click="searchCustomer" type="button">Search</button>
                  </div>

                </div>
              </div>
            </div>

            <hr/>

            <div class="row">
              <div class="col-md-12">
                <a class="btn btn-danger float-right" href="/apps/CreateQuotation" >Add new</a>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="quotations" :fields="quotation_columns">

              <template v-slot:cell(id)="data">
                <a style="text-decoration: underline;" :href="'/apps/quotation/'+data.item.id">{{ data.item.id }}</a>
              </template>

              <template v-slot:cell(customer)="data">
                <span v-if="data.item.customer">{{ data.item.customer.clientName }}</span>
              </template>

              <template v-slot:cell(shipmentLocation)="data">
                <span v-if="data.item.shipmentLocation">{{ data.item.shipmentLocation.locationName }}</span>
              </template>

              <template v-slot:cell(salesRep)="data">
                <span v-if="data.item.salesRep">{{ data.item.salesRep.name }}</span>
              </template>

              <template v-slot:cell(total)="data">
                {{ getTotal( data.item.items ) | currency("KES ", 0) }}
              </template>

              <template v-slot:cell(status)="data">
                <span v-if="data.item.status == 'CONFIRMED'" class="badge badge-success">Confirmed</span>
                <span v-else-if="data.item.status == 'PENDING'" class="badge badge-warning"> Pending </span>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" v-if="data.item.status == 'PENDING'" @click="openConfirmQuotationModal(data.item)" >Confirm</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openEditQuotation(data.item.id)" v-if="data.item.status == 'PENDING'" >Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteQuotationModal(data.item)" v-if="data.item.status == 'PENDING'" >Delete</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openSTKPushModal(data.item)" v-if="data.item.status == 'PENDING'" >Push STK</b-dropdown-item>
                  <b-dropdown-item href="#" @click="routeToPreviewToDownload(data.item.id)" >Preview To Download</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>

            <div class="row">
              <div class="col-md-12">

                <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                  <ul class="pagination justify-content-center">
                    <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                    <li v-for="page in pagesNumber"
                        v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                    <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>

          </b-tab>
          <b-tab title="Invoices" >
            <b-table striped hover :items="invoices" :fields="invoice_columns">

              <template v-slot:cell(docNumber)="data">
                <a style="text-decoration: underline;" :href="'/app/pages/invoice/'+data.item.id">{{ data.item.docNumber }}</a>
              </template>

              <template v-slot:cell(customerRef)="data">
                {{ data.item.customerRef.name }}
              </template>



              <template v-slot:cell(totalAmt)="data">
                {{ data.item.totalAmt | currency("", 0) }}
              </template>

              <template v-slot:cell(balance)="data">
                {{ data.item.balance | currency("", 0) }}
              </template>

              <template v-slot:cell(emailStatus)="data">
                <span v-if="data.item.balance == 0" class="badge badge-success">Paid</span>
                <span v-else-if="data.item.balance > 0 && data.item.balance < data.item.totalAmt" class="badge badge-warning"> Partially Paid  </span>
                <span v-else-if="data.item.balance == data.item.totalAmt" class="badge badge-danger"> Not Paid  </span>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openReceivePayment(data.item)" >Receive Payment</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>



          </b-tab>
          <b-tab title="Stanbic Payments">
            <div class="row">

              <div class="col-md-4">
                <label><strong>Filter by date:</strong></label> <br/>
                <date-picker style="width: 44%;"   :lang="lang" v-model="startDateBank" valueType="format" :first-day-of-week="1"></date-picker> to
                <date-picker style="width: 44%;"   :lang="lang" v-model="endDateBank" valueType="format"  @input="getBankTransactionsData" :first-day-of-week="1"></date-picker>
              </div>


              <div class="col-md-4">
              </div>

              <div class="col-md-4">
                <div class="input-group">

                  <input type="text" v-model="keywordBank" class="form-control" placeholder="Search by name, phone number, transaction Id" aria-label="" aria-describedby="basic-addon1">

                  <div class="input-group-append">
                    <button  class="btn btn-primary" style="color:#fff !important;" @click="searchTransaction" type="button">Search</button>
                  </div>

                </div>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="bankTransactions" :fields="payments_columns">
              <template v-slot:cell(amount)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span>{{ data.value | currency("", 0) }}</span>

              </template>

              <template v-slot:cell(status)="data">
                <span v-if="data.item.status == 'MATCHED'" class="badge badge-success">Confirmed</span>
                <span v-else-if="data.item.status == 'NOTMATCHED'" class="badge badge-warning"> Pending </span>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" v-if="data.item.status == 'NOTMATCHED'" @click="openMarkAsConfirmed(data.item)" >Mark as confirmed</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>

            <div class="row">
              <div class="col-md-12">

                <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                  <ul class="pagination justify-content-center">
                    <li v-if="pagination_transactions.current_page > 1" class="page-item"><a @click.prevent="changePageBankTransactions(pagination_transactions.current_page - 1)" class="page-link" href="#">Previous</a></li>
                    <li v-for="page in pagesNumberTransactions" v-bind:class="[ page == isActivedTransactions ? 'active' : '']" class="page-item"><a @click.prevent="changePageBankTransactions(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                    <li v-if="pagination_transactions.current_page < pagination_transactions.last_page"  class="page-item"><a  @click.prevent="changePageBankTransactions(pagination_transactions.current_page + 1)"class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>

          </b-tab>
          <b-tab title="NCBA Payments">
            <div class="row">

              <div class="col-md-6">
                <label><strong>Filter by date:</strong></label> <br/>
                <date-picker   :lang="lang" v-model="mpesa_start_date" valueType="format" :first-day-of-week="1"></date-picker> to
                <date-picker   :lang="lang" v-model="mpesa_end_date" valueType="format" @input="getMpesaTransactionsData"  :first-day-of-week="1"></date-picker>
              </div>
              <div class="col-md-3">

              </div>

              <div class="col-md-3">
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0 float-right">
                  <b-input v-model="transId" v-on:keyup.enter="searchMpesaTransactionsData"   placeholder="Search by Transaction #"></b-input>
                  <b-input-group-append>
                    <b-btn variant="success"   @click="searchMpesaTransactionsData"  >Find</b-btn>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
            <br/>

            <b-table
                    :items="mpesa_transactions"
                    :fields="mpesa_columns">


              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">

                </b-dropdown>
              </template>

            </b-table>

            <div class="row">
              <div class="col-md-12">
                <hr/>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">

                <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                  <ul class="pagination justify-content-center">
                    <li v-if="pagination_mpesa.current_page > 1" class="page-item"><a @click.prevent="changePageMpesa(pagination_mpesa.current_page - 1)" class="page-link" href="#">Previous</a></li>
                    <li v-for="page in pagesNumberMpesa"
                        v-bind:class="[ page == isActivedMpesa ? 'active' : '']" class="page-item"><a @click.prevent="changePageMpesa(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                    <li v-if="pagination_mpesa.current_page < pagination_mpesa.last_page"  class="page-item"><a  @click.prevent="changePageMpesa(pagination_mpesa.current_page + 1)"class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </b-tab>
          <b-tab title="Petty Cash" v-if="permissions.includes('View Petty Cash')">
            <div class="row">
              <div class="col-md-3">
                <label><strong>Filter by date:</strong></label> <br/>
                <date-picker style="width: 44%;"   :lang="lang" v-model="startDate" valueType="format" :first-day-of-week="1"></date-picker> to
                <date-picker style="width: 44%;"   :lang="lang" v-model="endDate" valueType="format"  @input="getPettyCashData" :first-day-of-week="1"></date-picker>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label><strong>Filter by status:</strong></label> <br/>
                  <select class="form-control" v-model="pettyCashStatus" @change="getPettyCashData" >
                    <option value="all">  All </option>
                    <option value="APPROVED" > Approved</option>
                    <option value="PENDING" > Pending</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label><strong>Filter by supplier:</strong></label> <br/>
                  <select class="form-control" v-model="filterSupplierId" @change="getPettyCashData" >
                    <option value="0">  All </option>
                    <option :value="recipientSupplier.id" v-for="recipientSupplier in suppliers" > {{ recipientSupplier.name }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <b-button class="btn btn-danger float-right" v-b-modal.add-petty-cash>Add new</b-button>
              </div>
            </div>
            <br/>
            <b-table striped hover :items="petty_cash" :fields="petty_cash_columns">
              <template v-slot:cell(amount)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span>{{ data.value | currency("", 0) }}</span>

              </template>

              <template v-slot:cell(raisedBy)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span>{{ data.item.raisedBy.name }}</span>

              </template>

              <template v-slot:cell(mpesaName)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span v-if="data.item.mpesaName">{{ data.item.mpesaName }}</span>
              </template>

              <template v-slot:cell(recipient)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span v-if="data.item.recipient">{{ data.item.recipient }}</span>
                <span v-else>{{ data.item.phoneNumber }}</span>
              </template>

              <template v-slot:cell(supplier)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <span v-if="data.item.supplier">{{ data.item.supplier.name }}</span>
                <span v-else>{{ data.item.paidTo }}</span>
              </template>

              <template v-slot:cell(status)="data">
                <span v-if="data.item.status == 'APPROVED'" class="badge badge-success">Approved</span>
                <span v-else-if="data.item.status == 'PENDING'" class="badge badge-warning"> Pending </span>
                <span v-else-if="data.item.status == 'REJECTED'" class="badge badge-danger"> Rejected </span>
                <span v-else-if="data.item.status == 'REJECTED' || data.item.status == 'CANCELLED' || data.item.status == 'FAILED' " class="badge badge-danger"> {{ data.item.status }} </span>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" v-if="data.item.status == 'PENDING' || (data.item.status == 'APPROVED' && !data.item.transactionId)" @click="openEditPettyCashModal(data.item)"  >Edit</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="permissions.includes('Approve Petty Cash') && data.item.status == 'PENDING'"  @click="openApprovePettyCash(data.item)"   >Approve</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="permissions.includes('Approve Petty Cash') && data.item.status == 'FAILED' && !data.item.transactionId"  @click="openApprovePettyCash(data.item)"   >Resend</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="permissions.includes('Approve Petty Cash') && data.item.status == 'PENDING'" @click="openRejectPettyCash(data.item)" >Reject</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="permissions.includes('Approve Petty Cash') && data.item.status == 'FAILED'" @click="openPettyCashMessage(data.item)" >View Message </b-dropdown-item>
                  <b-dropdown-item href="#" v-if="data.item.status == 'PENDING'" @click="openCancelPettyCash(data.item)" >Cancel</b-dropdown-item>
                  <b-dropdown-item href="#" v-if="data.item.status == 'APPROVED'" @click="openViewPettyCashModal(data.item)" >View Petty Cash</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <div class="row">
              <div class="col-md-12">

                <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
                  <ul class="pagination justify-content-center">
                    <li v-if="pagination_petty_cash.current_page > 1" class="page-item"><a @click.prevent="changePagePettyCash(pagination_petty_cash.current_page - 1)" class="page-link" href="#">Previous</a></li>
                    <li v-for="page in pagesNumberPettyCash"
                        v-bind:class="[ page == isActivedPettyCash ? 'active' : '']" class="page-item"><a @click.prevent="changePagePettyCash(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                    <li v-if="pagination_petty_cash.current_page < pagination_petty_cash.last_page"  class="page-item"><a  @click.prevent="changePagePettyCash(pagination_petty_cash.current_page + 1)" class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </b-tab>
          <b-tab title="Suppliers" v-if="permissions.includes('View Petty Cash')">
            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-supplier>Add new</b-button>
              </div>

            </div>
            <b-table striped hover :items="suppliers" :fields="supplier_columns">

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#"  @click="openEditSupplierModal(data.item)"  >Edit</b-dropdown-item>
                  <b-dropdown-item href="#"   @click="openDeleteSupplier(data.item)"   >Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>

        <b-modal id="add-quotation-items" size="lg" title="CONVERT QUOTATION TO INVOICE" @cancel="true"  @ok="confirmQuotation"  centered>

          <div class="row">
            <div class="col-md-12">
              <table class="table color-table muted-table">
                <thead>
                  <tr>
                    <th style="font-weight: 700;">Product</th>
                    <th style="font-weight: 700;">Quantity</th>
                    <th style="font-weight: 700;">Unit Cost </th>
                    <th style="font-weight: 700;">Discount</th>
                    <th style="font-weight: 700;">Total Amount</th>
                    <th style="font-weight: 700;">Tax</th>
                  </tr>
                </thead>
                <tbody>

                <tr v-for="(quotation_item,index) in quotation.items" >

                  <td style="padding: 10px;">
                     {{ quotation_item.product.productName }}
                  </td>

                  <td>
                    {{ quotation_item.qty }}
                  </td>

                  <td style="padding: 10px;">
                    <span v-if="quotation_item.product">{{ quotation_item.pricePerMetre }}</span>
                  </td>

                  <td>
                    {{ quotation_item.discount }}

                  </td>

                  <td>
                    <div v-if="quotation_item.product && quotation_item.product.productType == 'IRON_SHEET'">
                                                             <span v-if="quotation_item.qty && quotation_item.sizeInMetres" >
                                                              {{(parseFloat(quotation_item.qty) * parseFloat(quotation_item.sizeInMetres) * parseFloat(quotation_item.pricePerMetre)) - quotation_item.discount   | number('0,0') }}
                                                            </span>
                    </div>
                    <div v-else-if="quotation_item.product && quotation_item.product.productType == 'GENERAL'">
                                                            <span v-if="quotation_item.qty" >
                                                              {{( parseFloat(quotation_item.qty)  * parseFloat(quotation_item.pricePerMetre)) - quotation_item.discount | number('0,0') }}
                                                            </span>
                    </div>
                  </td>
                  <td>
                    <select class="form-control" v-model="quotation_item.taxId">
                      <option :value="taxRate.id" v-for="taxRate in taxRates">{{ taxRate.name }} </option>
                    </select>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </b-modal>

        <b-modal id="receive-payment" size="lg" title="Receive payment" @cancel="true"  @ok="submitInvoicePayment"  centered>

          <div class="row mb-3">
            <div class="col-md-6"></div>
            <div class="col-md-6">
              <div class="input-group">

                <input type="text" v-model="keywordBank" class="form-control" placeholder="Search by name, phone number, transaction Id" aria-label="" aria-describedby="basic-addon1">

                <div class="input-group-append">
                  <button  class="btn btn-primary" style="color:#fff !important;" @click="searchTransaction" type="button">Search</button>
                </div>

              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered">
                <tr>
                   <th></th>
                   <th>Date</th>
                   <th>Amount</th>
                   <th>Narrative</th>
                </tr>
                <tr v-for="payment in unmatchedPayments">
                     <td><input type="radio" v-model="selectedPayments" name="selectedPayments" :value="payment"></td>
                     <td>{{ payment.transactionDate }}</td>
                     <td>{{ payment.amount | currency("", 0) }}</td>
                     <td>{{ payment.narrative }}</td>
                </tr>
              </table>
            </div>
          </div>
        </b-modal>

        <b-modal id="add-petty-cash" size="lg" title="Add Petty Cash" @cancel="true"  @ok="submitPettyCash"  centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Payment Type:</label> <br/>
                    <div >
                      <select class="form-control" v-model="petty_cash_dto.paymentType">
                        <option> -- Select -- </option>
                        <option :value="paymentType" v-for="paymentType in paymentTypes">{{ paymentType }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Supplier:</label> <br/>
                    <div >
                      <select class="form-control" v-model="selectedSupplier">
                        <option> -- Select -- </option>
                        <option :value="recipientSupplier" v-for="recipientSupplier in suppliers">{{ recipientSupplier.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Supplier Details:</label> <br/>
                    <div >
                      <table class="table table-bordered">
                        <tr>
                          <th>Phone Number</th>
                          <td>{{ selectedSupplier.phoneNumber }}</td>
                        </tr>
                        <tr>
                          <th>Till Number</th>
                          <td>{{ selectedSupplier.tillNumber }}</td>
                        </tr>
                        <tr>
                          <th>Paybill Number</th>
                          <td>{{ selectedSupplier.paybillNumber }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Account Reference:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_dto.accountReference" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Amount:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_dto.amount" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Description:</label> <br/>
                    <div >
                      <textarea style="width: 100%;" class="form-control" rows="7" v-model="petty_cash_dto.description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </b-modal>

        <b-modal id="edit-petty-cash" size="lg" title="Edit Petty Cash" @cancel="true"  @ok="submitEditedPettyCash"  centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Payment Type:</label> <br/>
                    <div >
                      <select class="form-control" v-model="petty_cash_dto.paymentType">
                        <option> -- Select -- </option>
                        <option :value="paymentType" v-for="paymentType in paymentTypes">{{ paymentType }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Supplier:</label> <br/>
                    <div >
                      <select class="form-control" v-model="petty_cash_dto.supplier">
                        <option> -- Select -- </option>
                        <option :value="recipientSupplier" v-for="recipientSupplier in suppliers">{{ recipientSupplier.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Supplier Details:</label> <br/>
                    <div >
                      <table class="table table-bordered">
                        <tr>
                          <th>Phone Number</th>
                          <td><span v-if="petty_cash_dto.supplier">{{ petty_cash_dto.supplier.phoneNumber }}</span></td>
                        </tr>
                        <tr>
                          <th>Till Number</th>
                          <td><span v-if="petty_cash_dto.supplier">{{ petty_cash_dto.supplier.tillNumber }}</span></td>
                        </tr>
                        <tr>
                          <th>Paybill Number</th>
                          <td><span v-if="petty_cash_dto.supplier">{{ petty_cash_dto.supplier.paybillNumber }}</span></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Account Reference:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_dto.accountReference" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Amount:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_dto.amount" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Description:</label> <br/>
                    <div >
                      <textarea style="width: 100%;" class="form-control" rows="7" v-model="petty_cash_dto.description"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </b-modal>

        <b-modal id="view-petty-cash" size="lg" title="View Petty Cash" @cancel="true"    centered>

          <div class="row mt-3">
            <div class="col-md-12">
                <button type="button" class="btn btn-danger float-right" @click="printPettyCash">Download</button>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <hr/>
            </div>
          </div>

          <div id="pettyCashToPrint">

            <div class="row mb-4">
              <div class="col-md-6">
                <img src="@/assets/images/kings_mabati.png" style="width: 200px;height: 100px">
              </div>
              <div class="col-md-6">
                <div class="float-right">
                  <strong>KINGS MABATI COMPANY LIMITED,</strong><br/>
                  <strong>NATIONAL PARK EAST GATE ROAD,</strong><br/>
                  <strong>NAIROBI - KENYA</strong><br/>
                  <strong>Tel: 0114030351</strong><br/>
                  <strong>EMAIL :info@kingsmabati.co.ke</strong>

                </div>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <h2>PETTY CASH</h2>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-12 text-center">
                <table class="table table-bordered">
                  <tr>
                    <th>
                      NUMBER: {{ petty_cash_dto.id }}
                    </th>
                    <th>
                      DATE: {{ petty_cash_dto.date }}
                    </th>
                    <th>
                      Paid To: {{ petty_cash_dto.paidTo }}
                    </th>
                  </tr>
                </table>
              </div>
            </div>

            <div class="row mb-4" style="min-height: 300px">
              <div class="col-md-12 text-center">
                <table class="table table-bordered">
                  <tr>
                    <th>
                      DESCRIPTION OF ITEM
                    </th>
                    <th>
                      AMOUNT
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {{ petty_cash_dto.description }}
                    </td>
                    <td>
                      {{ petty_cash_dto.amount | currency("", 0) }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      Total
                    </th>
                    <th>
                      KES. {{ petty_cash_dto.amount | currency("", 0) }}
                    </th>
                  </tr>
                </table>
              </div>


            </div>

          </div>


        </b-modal>

        <b-modal id="add-petty-cash-budget" size="lg" title="Add Petty Cash" @cancel="true"  @ok="submitPettyCashBudget"  centered>

          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Name:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_budget_dto.name" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Amount:</label> <br/>
                    <div >
                      <input class="form-control" type="text"  v-model="petty_cash_budget_dto.amount" />
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </form>

        </b-modal>

        <b-modal id="pushSTK" size="sm" title="Push STK" @cancel="true" @ok="confirmSTK"   centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >
                      Phone Number: </label> <br/>
                    <input class="form-control" v-model="stkPush.phoneNumber" type="text" />
                  </div>
                  <div class="form-group ">
                    <label > Amount: </label>
                      <input  class="form-control" v-model="stkPush.amount" type="text" />
                  </div>
                </div>
              </div>
            </div>

          </form>
        </b-modal>

        <b-modal id="add-supplier" size="sm" title="Add Supplier" @cancel="true" @ok="submitSupplier"   centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Name: </label> <br/>
                    <input class="form-control" v-model="supplier.name" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Phone Number: </label> <br/>
                    <input class="form-control" v-model="supplier.phoneNumber" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Till Number: </label> <br/>
                    <input class="form-control" v-model="supplier.tillNumber" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Paybill Number: </label> <br/>
                    <input class="form-control" v-model="supplier.paybillNumber" type="text" />
                  </div>
                </div>
              </div>
            </div>

          </form>
        </b-modal>

        <b-modal id="edit-supplier" size="sm" title="Edit Supplier" @cancel="true" @ok="submitEditSupplier"   centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">
              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Name: </label> <br/>
                    <input class="form-control" v-model="supplier.name" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Phone Number: </label> <br/>
                    <input class="form-control" v-model="supplier.phoneNumber" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Till Number: </label> <br/>
                    <input class="form-control" v-model="supplier.tillNumber" type="text" />
                  </div>
                </div>
              </div>

              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label >Paybill Number: </label> <br/>
                    <input class="form-control" v-model="supplier.paybillNumber" type="text" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>

        <b-modal id="petty-cash-message" size="sm" title="Petty Cash" @cancel="true"    centered>
          <div class="row">
            <div class="col-12">
              <div class="alert alert-danger">
                <span v-if="petty_cash_dto.mpesaResultsDescription">{{ petty_cash_dto.mpesaResultsDescription }}</span>
              </div>
            </div>
          </div>
        </b-modal>


      </div>
    </div>
  </div>
</template>
<script>

  import draggable from "vuedraggable";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapGetters, mapActions } from "vuex";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

export default {
  display: "Transitions",
  order: 7,

  data() {
    return {
      pettyCashStatus:"all",
      filterSupplierId:0,
      selectedSupplier:{
        id:0,
        name:"",
        phoneNumber:"",
        tillNumber:"",
        paybillNumber:""
      },
      paymentTypes:["INDIVIDUAL", "BUYGOODS", "PAYBILL"],
      supplier:{
        id:0,
        name:"",
        phoneNumber:"",
        tillNumber:"",
        paybillNumber:""
      },
      stkPush:{
        quoteId:"",
        amount:0,
        phoneNumber:""
      },
      mpesa_columns:[ 'transID',{ key: 'created', label: 'Date' },{ key:'transAmount', label: 'Amount'},{ key: 'created', label: 'Date' },'billRefNumber',{ key: 'msisdn', label: 'Phone Number' },'firstName'],
      supplier_columns:[ 'id','name','phoneNumber','tillNumber','paybillNumber','options'],
      mpesa_transactions:[],
      transId:"",
      mpesa_start_date:"all",
      mpesa_end_date:"all",
      petty_cash_budget_dto:{
          id:0,
          name:"",
          amount:""

      },
      offset_mpesa: 4,
      pagination_mpesa:{
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      pagination_petty_cash:{
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset_petty_cash: 4,
      petty_cash_budgets:[],
      petty_cash:[],
      keywordBank:"",
      startDateBank:"all",
      endDateBank:"all",
      invoice:{},
      selectedPayments:{},
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      bankTransaction:{},
      currentPage:1,
      keyword:"",
      isLoading: false,
      fullPage: true,
      selected:[],
      invoices:[],
      quotations:[],
      quotation:{
        customer:"",
        location:"",
        items:[
          {
            product:"",
            qty:"",
            size:"",
            discount:0,
            tax_id:""
          }
        ]
      },
      petty_cash_dto:{
        id:0,
        paidTo:"",
        amount:"",
        description:"",
        phoneNumber:"",
        supplierId:"",
        paymentType:"",
        accountReference:"",
        supplier:{},
        mpesaResultsDescription:""
      },
      payments:[],
      bankTransactions:[],
      installments:[],
      startDate:"all",
      endDate:"all",
      quotationStatus:"all",
      offset_transactions: 4,
      pagination_transactions: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      offset: 4,
      pagination_invoices: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },

      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      quotation_columns: [
              {
        key: 'id',
        label:'#',
        sortable: false,
      },{
        key: 'quotationDate',
        label:'date',
        sortable: false,
      },"customer",{
        key: 'destination',
        label:'location',
        sortable: false,
      },"salesRep","status","total","options"],
      invoice_columns: [{
        key: 'docNumber',
        label:'Invoice #',
        sortable: false,
      },{
        key: 'customerRef',
        label:'Customer',
        sortable: false,
      },{
        key: 'txnDate',
        label:'Transaction Date',
        sortable: false,
      },{
        key: 'dueDate',
        label:'Due Date',
        sortable: false,
      },{
        key: 'totalAmt',
        label:'Amount (KES)',
        sortable: false,
      },{
        key: 'balance',
        label:'Balance (KES)',
        sortable: false,
      },{
        key: 'emailStatus',
        label:'Status',
        sortable: false,
      }, {
        key: 'privateNote',
        label:'Sales Rep',
        sortable: false,
      },"options"],
      payments_columns: ["transactionReference","transactionDate","narrative","amount","status","options"],
      petty_cash_columns: ["date","amount",{
        key: 'supplier',
        label:'Paid To',
        sortable: false,
      },"recipient","mpesaName","paymentType","transactionID","description","raisedBy","status", "options"],
      petty_cash_budget_columns: ["date","name","amount","addedBy","balance","status", "options"],
      lipa_pole_pole_columns:["date","clientName","targetAmount","amountPaid","startDate","endDate","options"],
      taxRates:[],
      suppliers:[]
    };
  },
  components: {
    Loading,
    DatePicker
  },
  computed: {
    ...mapGetters(["loading","error"]),
    pagesNumberPettyCash: function () {
      if (!this.pagination_petty_cash.to) {
        return [];
      }
      var from = this.pagination_petty_cash.current_page - this.offset_petty_cash;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset_petty_cash * 2);
      if (to >= this.pagination_petty_cash.last_page) {
        to = this.pagination_petty_cash.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }

      return pagesArray;
    },
    isActivedPettyCash: function () {
      return this.pagination_petty_cash.current_page + 1;
    },
    pagesNumberMpesa: function () {
      if (!this.pagination_mpesa.to) {
        return [];
      }
      var from = this.pagination_mpesa.current_page - this.offset_mpesa;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset_mpesa * 2);
      if (to >= this.pagination_mpesa.last_page) {
        to = this.pagination_mpesa.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }

      return pagesArray;
    },
    isActivedMpesa: function () {
      return this.pagination_mpesa.current_page + 1;
    },
    permissions(){

      return localStorage.getItem("permissions");
    },
    isActivedTransactions: function () {
      return this.pagination_transactions.current_page + 1;
    },
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    unmatchedPayments(){

      return this.bankTransactions.filter(function (bankTransaction) {

        return bankTransaction.status == "NOTMATCHED"
      });


      return [];
    },
    pagesNumberTransactions: function () {
      if (!this.pagination_transactions.to) {
        return [];
      }
      var from = this.pagination_transactions.current_page - this.offset_transactions;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset_transactions * 2);
      if (to >= this.pagination_transactions.last_page) {
        to = this.pagination_transactions.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }



      return pagesArray;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }

      return pagesArray;
    }

  },

  mounted (){
    this.getQuotationsData();
    this.getInvoicesData();
    this.getTaxRatesData();
    this.getBankTransactionsData();
    this.getPettyCashData();
    this.getPettyCashBudgetsData();
    this.getMpesaTransactionsData();
    this.getSuppliersData();
  },
  methods:{
    ...mapActions(["initiateSTKPush","searchMpesaTransactions","getMpesatransactions","getPettyCashBudgets","createPettyCashBudget","getPettyCash","editPettyCash","createPettyCash","updatePettyCashStatus","updateBankTransaction","searchBankTransactions","receivePayment","getQuotations","createInvoiceFromQuotation","deleteQuotation","searchQuotationsByCustomerName","getInvoices","getTaxRates","getBankTransactions","createSupplier","editSupplier","deleteSupplier","getSuppliers","getPettyCashSuppliers"]),

    changePageMpesa: function (page) {
      this.pagination_mpesa.current_page = page;
      this.getMpesaTransactionsData();
    },
    searchMpesaTransactionsData(){

      if(this.transId){
        let self = this;

        self.isLoading = true;

        this.searchMpesaTransactions({transId:this.transId})
                .then(function (payments){

                  self.isLoading = false;

                  self.mpesa_transactions = payments;



                })
                .catch(function (error) {

                  self.isLoading = false;

                  if( error.response){
                    if(error.response.status == 401){
                      location.replace("/login");
                    }

                  }
                })
      }
      else{
        alert("Please enter transaction ID");
      }

    },
    getMpesaTransactionsData(){
      let self = this;

      this.isLoading = true;

      this.getMpesatransactions({startDate: this.mpesa_start_date, endDate: this.mpesa_end_date, page:this.pagination_mpesa.current_page})
              .then(function (payments){

                self.isLoading = false;
                self.mpesa_transactions = payments.content;

                if(payments.content){
                  self.pagination_mpesa.current_page = payments.number;
                  self.pagination_mpesa.total = payments.totalElements;
                  self.pagination_mpesa.per_page = payments.numberOfElements;
                  self.pagination_mpesa.from = payments.pageable.offset + 1 ;
                  self.pagination_mpesa.to = payments.pageable.pageSize;
                  self.pagination_mpesa.last_page = payments.totalPages ;
                }


              })
              .catch(function (error) {

                self.isLoading = false;

                if( error.response){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
    },
    printPettyCash(){
      this.$htmlToPaper("pettyCashToPrint");
    },

    openViewPettyCashModal(petty_cash){

      this.petty_cash_dto = petty_cash;

      this.$bvModal.show('view-petty-cash');

    },

    openEditSupplierModal(supplier){

      this.supplier = supplier;

      this.$bvModal.show('edit-supplier');

    },

    openEditPettyCashModal(petty_cash){

      this.petty_cash_dto = petty_cash;
      this.petty_cash_dto.supplierId = petty_cash.supplier.id

      this.$bvModal.show('edit-petty-cash');

    },
    openReceivePayment(invoice){
      this.invoice = invoice;
      this.$bvModal.show('receive-payment');
    },

    openPettyCashMessage(petty_cash_dto){
      this.petty_cash_dto = petty_cash_dto;
      this.$bvModal.show('petty-cash-message');
    },

    openRejectPettyCash(petty_cash){
      let petty_cash_dto = {
        id:petty_cash.id,
        status:"REJECTED"
      };

      let self = this;
      this.$bvModal.msgBoxConfirm('Reject petty cash ?')
              .then(value => {

                if(value){
                  self.updatePettyCashStatusData(petty_cash_dto);
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openCancelPettyCash(petty_cash){
      let petty_cash_dto = {
        id:petty_cash.id,
        status:"CANCELLED"
      };

      let self = this;
      this.$bvModal.msgBoxConfirm('Cancel petty cash ?')
              .then(value => {

                if(value){
                  self.updatePettyCashStatusData(petty_cash_dto);
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openApprovePettyCash(petty_cash){
      if(petty_cash.paymentType == "PAYBILL") {
        if(petty_cash.accountReference) {
          this.presentApproveModal(petty_cash)
        } else {
          this.$bvToast.toast("Please update account reference for paybill", {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          this.openEditPettyCashModal(petty_cash);
        }

      } else {
        this.presentApproveModal(petty_cash)
      }
    },

    presentApproveModal(petty_cash) {
      let petty_cash_dto = {
        id:petty_cash.id,
        status:"APPROVED"
      };

      let self = this;
      this.$bvModal.msgBoxConfirm('Confirm petty cash approval ?')
          .then(value => {

            if(value){
              self.updatePettyCashStatusData(petty_cash_dto);
            }

          })
          .catch(err => {
            // An error occurred
          })
    },

    openMarkAsConfirmed(bankTransaction){
      this.bankTransaction = bankTransaction;

      let self = this;
      this.$bvModal.msgBoxConfirm('Mark this transaction as confirmed ?')
              .then(value => {

                if(value){
                  self.confirmBankTransaction();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },
    openDeleteSupplier(supplier){
      this.supplier = supplier;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete supplier - '+supplier.name+'?')
          .then(value => {

            if(value){
              self.confirmDeleteSupplier();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    changePageBankTransactions: function (page) {
      this.pagination_transactions.current_page = page;
      this.getBankTransactionsData();
    },
    changePage: function (page) {
      this.pagination.current_page = page;
      this.getQuotationsData();
    },

    changePagePettyCash: function (page) {
      this.pagination_petty_cash.current_page = page;
      this.getPettyCashData();
    },

    getTotal(quotationItems){
      let sumTotal = quotationItems.reduce(function (previousValue, currentValue) {
           if(currentValue.product.productType == 'IRON_SHEET'){
             return previousValue + (currentValue.pricePerMetre *  currentValue.qty * currentValue.sizeInMetres)  - currentValue.discount;
           }
           else {
             return previousValue + (currentValue.pricePerMetre *  currentValue.qty) - currentValue.discount;
           }

      }, 0);

      return sumTotal;
    },

    routeToPreviewToDownload(id){

      location.replace('/app/pages/downloadQuotation/'+id)
    },

    openEditQuotation(id){

      location.replace('/app/pages/editQuotation/'+id)
    },

    openSTKPushModal(quotation){


      this.stkPush.amount = this.getTotal(quotation.items);
      this.stkPush.quoteId = quotation.id;
      this.stkPush.phoneNumber = quotation.customer.phoneNumber ? quotation.customer.phoneNumber :"";

      this.$bvModal.show('pushSTK');

    },

    openDeleteQuotationModal(quotation){

      this.quotation = quotation;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete quotation for '+this.quotation.customer.clientName  +' ?')
              .then(value => {

                if(value){
                  self.confirmDeleteQuotation();
                }

              })
              .catch(err => {
                // An error occurred
              })
    },

    openConfirmQuotationModal(quotation){

      this.quotation = quotation;

     /* this.quotation.items = this.quotation.items.map(function (item) {
           item.tax_id = 0;
           return item;
      });

      console.log(this.quotation);*/

      this.$bvModal.show('add-quotation-items');


    },

    submitEditedPettyCash(){
      let self = this;

      this.isLoading = true;
      this.petty_cash_dto.supplierId = this.petty_cash_dto.supplier.id

      this.editPettyCash(this.petty_cash_dto)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Petty cash saved", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getPettyCashData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    confirmSTK(){
      let self = this;

      this.isLoading = true;


      this.initiateSTKPush(this.stkPush)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("STK push prompt initiated!", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });


              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    submitPettyCashBudget(){
      let self = this;

      this.isLoading = true;


      this.createPettyCashBudget(this.petty_cash_budget_dto)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Petty cash budget created", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getPettyCashBudgetsData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    submitPettyCash(){
      let self = this;

      this.isLoading = true;

      this.petty_cash_dto.supplierId = this.selectedSupplier.id;

      this.createPettyCash(this.petty_cash_dto)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Petty cash created", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getPettyCashData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    submitInvoicePayment(){
      let self = this;

      this.isLoading = true;



      let invoicePayment = {
        invoiceId : this.invoice.id,
        amount: this.selectedPayments.amount,
        customerId: this.invoice.customerRef.value,
        transactionId: this.selectedPayments.id
      };


      this.receivePayment(invoicePayment)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Payment received", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getInvoicesData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    updatePettyCashStatusData(petty_cash_dto){
      let self = this;

      this.isLoading = true;

      this.updatePettyCashStatus(petty_cash_dto)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Petty cash is "+petty_cash_dto.status, {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getPettyCashData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    confirmBankTransaction(){
      let self = this;

      this.isLoading = true;

      this.updateBankTransaction(this.bankTransaction)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Transaction marked as confirmed", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getBankTransactionsData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    confirmDeleteSupplier(){
      let self = this;

      this.isLoading = true;

      this.deleteSupplier(this.supplier)
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Supplier deleted", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getSuppliersData();

          })
          .catch(function (error) {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },

    confirmDeleteQuotation(){
      let self = this;

      this.isLoading = true;

      this.deleteQuotation(this.quotation)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Quotation deleted.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getQuotationsData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    confirmQuotation(){
      let self = this;

      this.isLoading = true;


      let quotationItems = this.quotation.items.map(function (quotationItem) {
            return {quotationItemId: quotationItem.id, taxId: quotationItem.taxId}
      });

      let quotationData = {
        quotationId: this.quotation.id,
        invoiceQuotationItems:quotationItems
      };


      this.createInvoiceFromQuotation(quotationData)
              .then(function () {
                self.isLoading = false;

                self.$bvToast.toast("Quotation confirmed and invoice created.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getQuotationsData();

              })
              .catch(function (error) {
                self.isLoading = false;

                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });

              })
    },

    searchTransaction(){
      let self = this;

      this.isLoading = true;


      this.searchBankTransactions({keyword:this.keywordBank})
              .then(function (bankTransactions) {

                self.isLoading = false;

                self.bankTransactions = bankTransactions;



              })
              .catch(function (error) {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              });
    },

    searchCustomer(){

      this.isLoading = true;

      let self = this;
      this.searchQuotationsByCustomerName({keyword:this.keyword})
              .then(function (quotations) {

                self.isLoading = false;

                self.quotations = quotations;



              })
              .catch(function (error) {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              });
    },

    getTaxRatesData(){
      this.isLoading = true;

      let self = this;
      this.getTaxRates()
              .then(function (taxRates) {

                self.isLoading = false;

                console.log(taxRates);

                self.taxRates = taxRates;



              })
              .catch(function (error) {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              });
    },
    getBankTransactionsData(){
      this.isLoading = true;


      let self = this;
      this.getBankTransactions({startDate: this.startDateBank, endDate:this.endDateBank, page: this.pagination_transactions.current_page})
              .then(function (bankTransactions) {

                self.isLoading = false;

                self.bankTransactions = bankTransactions.content;

                if(bankTransactions.content){
                  self.pagination_transactions.current_page = bankTransactions.number;
                  self.pagination_transactions.total = bankTransactions.totalElements;
                  self.pagination_transactions.per_page = bankTransactions.numberOfElements;
                  self.pagination_transactions.from = bankTransactions.pageable.offset + 1 ;
                  self.pagination_transactions.to = bankTransactions.pageable.pageSize;
                  self.pagination_transactions.last_page = bankTransactions.totalPages ;
                }



              })
              .catch(function (error) {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              });
    },
    getInvoicesData(){

      let self = this;

      this.isLoading = true;
      this.getInvoices({page: 1})
              .then(function (invoices) {
                console.log(invoices)

                self.isLoading = false;
                self.invoices = invoices;

              })
              .catch(function (error) {

                self.isLoading = false;

                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              });
    },

    getPettyCashBudgetsData(){
      let self = this;
      this.getPettyCashBudgets()
              .then(function (petty_cash_budgets) {
                self.petty_cash_budgets = petty_cash_budgets;
              })
              .catch(function (error) {


                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
    },

    getPettyCashData(){
      let self = this;
      this.getPettyCash({pettyCashStatus: this.pettyCashStatus,startDate:this.startDate, endDate:this.endDate, supplierId: this.filterSupplierId, page:this.pagination_petty_cash.current_page})
              .then(function (petty_cash) {
                self.petty_cash = petty_cash.content;

                if(petty_cash.content){
                  self.pagination_petty_cash.current_page = petty_cash.number;
                  self.pagination_petty_cash.total = petty_cash.totalElements;
                  self.pagination_petty_cash.per_page = petty_cash.numberOfElements;
                  self.pagination_petty_cash.from = petty_cash.pageable.offset + 1 ;
                  self.pagination_petty_cash.to = petty_cash.pageable.pageSize;
                  self.pagination_petty_cash.last_page = petty_cash.totalPages ;
                }


              })
              .catch(function (error) {


                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    location.replace("/login");
                  }

                }
              })
    },

    getQuotationsData(){
      let self = this;
      this.getQuotations({page:this.pagination.current_page,quotationStatus:this.quotationStatus,startDate:this.startDate,endDate:this.endDate})
        .then(function (quotations) {

          self.quotations = quotations.content;

          if(quotations.content){
            self.pagination.current_page = quotations.number;
            self.pagination.total = quotations.totalElements;
            self.pagination.per_page = quotations.numberOfElements;
            self.pagination.from = quotations.pageable.offset + 1 ;
            self.pagination.to = quotations.pageable.pageSize;
            self.pagination.last_page = quotations.totalPages ;
          }



        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getSuppliersData(){
      let self = this;
      this.getSuppliers()
          .then(function (suppliers) {

            self.suppliers = suppliers;



          })
          .catch(function (error) {

          })
    },
    submitSupplier(){
      let self = this;

      this.isLoading = true;

      this.createSupplier(this.supplier)
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Supplier created", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getSuppliersData();

          })
          .catch(function (error) {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    submitEditSupplier(){
      let self = this;

      this.isLoading = true;

      this.editSupplier(this.supplier)
          .then(function () {
            self.isLoading = false;

            self.$bvToast.toast("Supplier updated", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            self.getSuppliersData();

          })
          .catch(function (error) {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    }


  }
};
</script>
